import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../../components/app/formBuilder/index'
import CustomDrawer from '../../../../../components/common/drawer'
import { useCreateEmployee } from '../../../../employees/api'
import { EmployeeSchema, formSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  handleRefresh?: () => void
  subSection?: boolean
  parentParams?: { id?: string; name?: string }
  handleCallback?: (data: any) => void
}

export default function CreateEmployee({
  isDrawerOpen,
  handleClose,
  subSection,
  handleCallback,
  parentParams,
  handleRefresh,
}: Props) {
  const [saveContinue, setSaveContinue] = useState(false)

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    isDuplicateCheck = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    ...(isDuplicateCheck ? { isDuplicateCheck: true } : {}),
  })

  const formBuilderProps = [
    textField('first_name', 'First Name', 'Enter first name', true, true),
    textField('last_name', 'Last Name', 'Enter last name', true, true),
    {
      ...textField('email', 'Email', 'Enter email', true, true),
      type: 'email',
    },
    {
      ...textField('mobile', 'Mobile', 'Enter Mobile', true, true),
      type: 'phone',
    },
  ]

  const methods = useForm<EmployeeSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const handleSubmission = (data: any) => {
    methods.reset()
    if (!saveContinue) {
      if (!subSection) {
      }
      handleClearAndClose()
      handleCallback?.(data)
    } else {
      methods.reset({
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
      })
      handleCallback?.(data)
    }
  }
  const { mutate, isLoading } = useCreateEmployee(handleSubmission)
  const onSubmit = (data: any, saveAndContinue: boolean) => {
    setSaveContinue(saveAndContinue)
    mutate(
      {
        user_type: 'vendor_employee',
        vendor_id: parentParams?.id,
        user: {
          ...data,
          username: data.email,
        },
      },
      {
        onSuccess: () => {
          saveAndContinue ? handleSaveAndContinue?.() : methods.reset({})
        },
      }
    )
  }
  const handleSaveAndContinue = () => {
    methods.reset({})
    handleRefresh?.()
  }
  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({})
    handleClose()
  }
  return (
    <>
      <CustomDrawer
        className="formDrawer"
        open={isDrawerOpen}
        handleClose={() => handleClearAndClose()}
        actionLoader={isLoading}
        handleSaveAndContinue={
          subSection ? undefined : handleSubmit((data) => onSubmit(data, true))
        }
        handleSubmit={handleSubmit((data) => onSubmit(data, false))}
        title="Create New Employee"
      >
        <div className="flex flex-col gap-4">
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={true} />
          </FormProvider>
        </div>
      </CustomDrawer>
    </>
  )
}
