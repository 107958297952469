import { InlineAutoComplete } from 'qbs-core'
import { convertUTCtoBrowserTimeZone } from '../../utilities/format'
import { getNestedProperty, useCurrencyFormat } from '../../utilities/parsers'
import { checkDetailPermission } from './permission'

const defaultColumnProps = {
  sortable: true,
  resizable: true,
  isVisible: true,
}
const defaultColumnProps1 = {
  sortable: false,
  isVisible: false,
  resizable: true,
}
const defaultColumnProps2 = {
  sortable: true,
  isVisible: false,
  resizable: true,
}
const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({
  onViewAction,
  franchisees,
  handleFranchiseeeChange,
  updateKey,
}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    const format = useCurrencyFormat

    if (isCustom === 'amount') {
      const propertyValue = getNestedProperty(row, key)
      const numbers =
        typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

      return {
        cell: format(numbers),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    } else if (isCustom === 'tier') {
      const tier = getNestedProperty(row, key)
      switch (tier) {
        case 'gatekeeper':
          return { cell: <div>Gatekeeper</div> }
        case 'influencer':
          return { cell: <div>Influencer</div> }
        case 'blocker':
          return { cell: <div>Blocker</div> }
        case 'decision-maker':
          return { cell: <div>Decision Maker</div> }
        default:
          return {
            cell: getNestedProperty(row, key),
            toolTip: getNestedProperty(row, key),
          }
      }
    } else if (isCustom === 'fulldate') {
      const propertyValue = getNestedProperty(row, key)

      return {
        cell: convertUTCtoBrowserTimeZone(propertyValue),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    } else if (isCustom === 'source') {
      return {
        cell: (
          <>
            {row?.created_source && (
              <span
                className={`${
                  row?.created_source !== 'DIRECT'
                    ? 'cursor-pointer text-link'
                    : ''
                }  `}
                onClick={() => onViewAction(row, 'source')}
              >
                {row?.created_source}
              </span>
            )}
          </>
        ),
      }
    } else if (isCustom === 'franchisee') {
      const disabled = !checkDetailPermission('change', 'franchisee')
      return {
        cell: (
          <>
            {disabled ? (
              <>{row?.franchisee?.franchisee_display_name}</>
            ) : (
              <InlineAutoComplete
                name="franchisee_id"
                hideClose={true}
                desc="franchisee_display_name"
                key={updateKey}
                data={franchisees}
                descId="id"
                type="custom_search_select"
                placeholder={'Select Franchisee'}
                onChange={(e) => handleFranchiseeeChange(e, row)}
              />
            )}
          </>
        ),
      }
    } else {
      return {
        cell: getNestedProperty(row, key),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    }
  }

  const column = [
    {
      title: 'Contact Name',
      field: 'name',
      ...defaultColumnProps,
      link: true,
      sortKey: 'default_contact__first_name',
      getPath: (row: any) => onViewAction(row, 'contactName'),
    },
    {
      title: 'Account Name',
      field: 'business_name',
      ...defaultColumnProps,
      sortKey: 'primary_company__business_name',
      renderCell: createRenderCell('primary_company.business_name'),
      customCell: true,
    },
    {
      title: 'Franchisee',
      field: 'Franchisee',

      renderCell: createRenderCell(
        'franchisee.franchisee_display_name',
        'franchisee'
      ),
      customCell: true,

      ...defaultColumnProps3,
    },
    {
      title: 'Origin',
      field: 'created_source',
      // sortKey: 'created_by__user__first_name',
      renderCell: createRenderCell('created_source', 'source'),
      customCell: true,
      ...defaultColumnProps,
    },
    {
      title: 'Mobile 1',
      renderCell: createRenderCell('default_contact.mobile'),
      field: 'mobile',
      ...defaultColumnProps1,
      customCell: true,
    },
    {
      title: 'Mobile 2',
      renderCell: createRenderCell('default_contact.mobile_2'),
      field: 'mobile_2',
      ...defaultColumnProps1,
      customCell: true,
    },
    {
      title: 'Email 1',
      renderCell: createRenderCell('default_contact.email'),
      field: 'email',
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Email 2',
      renderCell: createRenderCell('default_contact.email_2'),
      field: 'email',
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Accounts Count',
      field: 'account_count',
      ...defaultColumnProps2,
      sortable: false,
    },
    {
      title: 'Address 1: Line 1',
      renderCell: createRenderCell('addresses.0.address_1'),
      field: 'address_1',
      ...defaultColumnProps1,
      customCell: true,
    },
    {
      title: 'Address 1: Line 2',
      renderCell: createRenderCell('addresses.0.address_2'),
      field: 'address_2',
      ...defaultColumnProps1,
      customCell: true,
    },
    {
      title: 'Address 1: Country',
      renderCell: createRenderCell('addresses.0.country'),
      field: 'country',
      ...defaultColumnProps2,
      customCell: true,
      sortKey: 'customer_address__country',
    },
    {
      title: 'Address 1: State',
      renderCell: createRenderCell('addresses.0.state'),
      field: 'state',
      ...defaultColumnProps,
      customCell: true,
      sortKey: 'customer_address__state',
    },
    {
      title: 'Address 1: City',
      renderCell: createRenderCell('addresses.0.city'),
      field: 'city',
      ...defaultColumnProps3,
      customCell: true,
    },
    {
      title: 'Address 1: Pincode',
      renderCell: createRenderCell('addresses.0.pin_code'),
      field: 'pin_code',
      ...defaultColumnProps1,
      customCell: true,
    },
    // {
    //   title: 'Address 2: Line 1',
    //   renderCell: createRenderCell('addresses.1.address_1'),
    //   field: 'address_1',
    //   ...defaultColumnProps1,
    //   customCell: true,
    // },
    // {
    //   title: 'Address 2: line 2',
    //   renderCell: createRenderCell('addresses.1.address_2'),
    //   field: 'address_2',
    //   ...defaultColumnProps1,
    //   customCell: true,
    // },
    // {
    //   title: 'Adress 2: Country',
    //   renderCell: createRenderCell('addresses.1.country'),
    //   field: 'country',
    //   ...defaultColumnProps2,
    //   customCell: true,
    //   sortKey: 'customer_address__country',
    // },
    // {
    //   title: 'Address 2: State',
    //   renderCell: createRenderCell('addresses.1.state'),
    //   field: 'state',
    //   ...defaultColumnProps2,
    //   customCell: true,
    //   sortKey: 'customer_address__state',
    // },
    // {
    //   title: 'Address 2: City',
    //   renderCell: createRenderCell('addresses.1.city'),
    //   field: 'city',
    //   ...defaultColumnProps1,
    //   customCell: true,
    // },
    // {
    //   title: 'Address 2: Pincode',
    //   renderCell: createRenderCell('addresses.1.pin_code'),
    //   field: 'pin_code',
    //   ...defaultColumnProps1,
    //   customCell: true,
    // },
    {
      title: 'Created On',
      field: 'datetime_created',
      sortKey: 'datetime_created',
      type: 'text',
      renderCell: createRenderCell('datetime_created', 'fulldate'),
      customCell: true,
      ...defaultColumnProps,
    },
    {
      title: 'Updated On',
      field: 'datetime_updated',
      sortKey: 'datetime_updated',
      type: 'text',
      renderCell: createRenderCell('datetime_updated', 'fulldate'),
      customCell: true,
      ...defaultColumnProps,
    },
    {
      title: 'Created By',
      field: 'created_by',
      ...defaultColumnProps,
      sortKey: 'created_by__user__first_name',
    },
  ]

  return column
}
