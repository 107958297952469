import { z } from 'zod'

import noLeadingSpaces from '../../../utilities/noLeadingSpaces'

export const formSchema = z.object({
  first_name: z
    .string({ invalid_type_error: 'First Name is required.' })
    .min(1, { message: 'First Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  last_name: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),

  preferred_language: z.string().optional().nullable(),
  preferred_language_id: z.string().optional().nullable(),

  date_of_birth: z
    .union([z.date(), z.string()])
    .refine((date) => date !== undefined && date !== null && date !== '', {
      message: 'Date of Birth is required.',
    })
    .optional()
    .nullable(),
  pan_no: z
    .string()
    .refine((value) => value === '' || /^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value), {
      message: 'Invalid PAN number format',
    })
    .refine((value) => value === '' || noLeadingSpaces(value), {
      message: 'Leading spaces are not allowed',
    })
    .optional(),
  mobile: z
    .string()
    .refine(
      (value) => {
        return (
          value === '' ||
          /^\+(\d{1,4})$/.test(value) ||
          /^(\+?\d{10,12})$/.test(value)
        )
      },
      {
        message: 'Invalid mobile number.',
      }
    )
    .optional(),
  email: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional(),

  account_id: z.string().optional().nullable(),
  account_name: z.string().min(1, 'Primary account is required.'),
  customer_tier: z.string().optional().nullable(),

  // Address Information

  state: z.string().min(1, 'State is required.'),
  stateId: z.string().optional().nullable(),
  code: z.string().optional().nullable(),
  city: z.string().min(1, 'City is required.').refine(noLeadingSpaces, {
    message: 'Leading spaces are not allowed',
  }),
  address_1: z.string().optional().nullable(),
  address_2: z.string().optional().nullable(),
  country: z.string().min(1, 'Country is required.'),
  countryId: z.string().optional().nullable(),

  pin_code: z.string().optional().nullable(),

  // Contact Information

  mobile_2: z
    .string()
    .refine(
      (value) => {
        return (
          value === '' ||
          /^\+(\d{1,4})$/.test(value) ||
          /^(\+?\d{10,12})$/.test(value)
        )
      },
      {
        message: 'Invalid mobile number.',
      }
    )
    .optional()
    .nullable(),

  email_2: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional(),
})

export const createContact = formSchema.omit({
  state: true,
  city: true,
  email: true,
  mobile: true,
})

export const basicInformationSchema = formSchema.omit({
  state: true,
  city: true,
  email: true,
  mobile: true,
  country: true,
})

export const addressInformationSchema = formSchema.omit({
  first_name: true,
  preferred_language: true,
  preferred_language_id: true,
  pan_no: true,
  date_of_birth: true,
  email: true,
  mobile: true,
  account_name: true,
})

export const contactInformationSchema = formSchema.omit({
  first_name: true,
  preferred_language: true,
  preferred_language_id: true,
  pan_no: true,
  date_of_birth: true,
  state: true,
  country: true,
  city: true,
  account_name: true,
})
export const contactSchema = formSchema.omit({
  country: true,
})
export const subSectionSchema = formSchema.omit({
  country: true,
  account_id: true,
  account_name: true,
})

export type ContactSchema = z.infer<typeof contactSchema>

export const getMergedSchema = (condition?: boolean) => {
  return condition ? subSectionSchema : contactSchema
}

export type BasicInformationSchema = z.infer<typeof basicInformationSchema>

export type AddressInformationSchema = z.infer<typeof addressInformationSchema>

export type ContactInformationSchema = z.infer<typeof contactInformationSchema>
