import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  fetchAccountContacts,
  getAccounts,
  getEmployeeList,
} from '../../../../apis/common.apis'
import FormBuilder from '../../../../components/app/formBuilder/index'
import { Button } from '../../../../components/common'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import FormLinkView from '../../../../components/common/inputs/FormLink'
import { allowActionsByStatusExcept } from '../../../../configs/permissionGate'
import { router_config } from '../../../../configs/route.config'
import { convertUTCtoBrowserTimeZone } from '../../../../utilities/format'
import { getLeadSource, useEditQuote } from '../../api'
import { checkDetailPermission, checkPermission } from '../../permission'
import { OrderEditSchema, orderEditSchema } from './schema'

type Props = {
  data: any
  id: string
  handleRefresh: () => void
}
const BasicInformation = (props: Props) => {
  const { data: detailsData, id, handleRefresh } = props
  const [isEditing, setIsEditing] = useState(false)
  const [accountPage, setAccountPage] = useState(1)
  const [contactPage, setContactPage] = useState(1)
  const [data, setData] = useState(detailsData)
  const [employeePage, setEmployeePage] = useState(1)
  const [leadPage, setLeadPage] = useState(1)
  useEffect(() => {
    setData(detailsData)
  }, [detailsData])

  const onInit = () => {
    return {
      order_date: data?.order_date
        ? moment(data?.order_date).format('YYYY-MM-DD')
        : new Date(),
      order_number: data?.order_number,
      contact_id: data?.contact?.id,
      contact_name: data?.contact?.name,
      account_id: data?.account?.id,
      account_name: data?.account?.business_name,
      assigned_to_name: data?.assigned_to?.name,
      assigned_to: data?.assigned_to?.id,
      lead_source_id: data?.lead_source?.id ?? null,
      lead_source_name: data?.lead_source?.name ?? null,
      internal_campaign_id: data?.internal_campaign_id ?? '',
      total_services_count: data?.total_services_count,
      spot_incentive:
        data?.spot_incentive && data?.spot_incentive !== ''
          ? Number(data?.spot_incentive)
          : 0,
      datetime_created: convertUTCtoBrowserTimeZone(data?.datetime_created),
      datetime_updated: convertUTCtoBrowserTimeZone(data?.datetime_updated),
      order_status_id: data?.order_status?.id,
      order_status_name: data?.order_status?.name,
      created_by: data?.created_by?.name,
      order_comment: data?.order_comment ?? '',
      quote_note: data?.quote_note ?? '',
      customer_communication_mode: data?.customer_communication_mode,
      customer_message: data?.customer_message,
    }
  }

  const methods = useForm<OrderEditSchema>({
    resolver: zodResolver(orderEditSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })

  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const { account_id } = methods.watch()

  const getAcccount = async (search?: string, page?: number) => {
    const data = await getAccounts({
      search: search as string,
      page: page as number,
      type: 'dropdown',
    })
    const next = data.next ? accountPage + 1 : 0
    setAccountPage(next)
    return data?.results
  }

  const getContact = async (search?: string, page?: number) => {
    const datas = await fetchAccountContacts(
      {
        search: search as string,
        page: page as number,
        type: 'dropdown',
      },
      account_id
    )
    const next = datas.next ? contactPage + 1 : 0
    setContactPage(next)
    return datas?.data?.map((item: any) => ({
      ...item,
      customer_cre:
        item.customer_info?.first_name + ' ' + item.customer_info?.last_name,
    }))
  }

  const loadEmployeeList = async (value?: string, page?: number) => {
    const datas = await getEmployeeList({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    const next = datas.next ? employeePage + 1 : 0
    setEmployeePage(next)
    return datas?.results?.map((item: any) => ({
      ...item,
      employee_name: item.name,
    }))
  }

  const getLead = async (value?: string, page?: number) => {
    const { data } = await getLeadSource({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    const next = data.next ? leadPage + 1 : 0
    setLeadPage(next)
    return data?.map((item: any) => ({
      ...item,
      lead_source_name: item.name,
    }))
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    value: string,
    required = false,
    type = 'text',
    disabled = false
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    value,
    ...(required ? { required: true } : {}),
    ...(disabled ? { disabled: true } : {}),
  })

  const handleUpdate = async (count: any) => {
    if (count > 0) {
      const data = await getLead()
      const item = data?.find((items: any) => items.name === 'Existing')
      methods.setValue('lead_source_name', item.name)
      methods.setValue('lead_source_id', item.id)
    } else {
      methods.setValue('lead_source_name', null)
      methods.setValue('lead_source_id', null)
    }
  }
  const handleClear = (sel: any) => {
    handleUpdate(sel?.active_order_count)
    methods.setValue('contact_name', '')
    methods.setValue('contact_id', '')
  }
  const formBuilderProps = [
    {
      ...textField(
        'order_date',
        'Quote Date',
        'Enter Quote Date',
        data.quote_date
          ? moment(data?.quote_date).format('DD-MM-YYYY')
          : moment(data?.order_date).format('DD-MM-YYYY')
      ),
      maxDate: new Date(),
      type: 'date',
      required: true,
      disabled: !checkDetailPermission('change', 'date'),
      spacing: 6,
    },
    {
      ...textField(
        'order_number',
        'Quote Id',
        'Enter Quote Number',
        data?.order_number,
        true,
        'text',

        true
      ),
      spacing: 6,
    },
    {
      name: 'account_name',
      label: 'Account',
      required: true,
      getData: getAcccount,
      async: true,
      id: 'account_id',
      link: `${router_config.ACCOUNTS.path}/${data?.account?.id}/summary`,
      paginationEnabled: true,
      nextBlock: accountPage,
      descId: 'id',
      initialLoad: true,
      desc: 'business_name',
      type: 'auto_complete',
      placeholder: 'Enter Account',
      value: data?.account?.business_name,
      spacing: 6,
      handleCallBack: handleClear,
    },
    {
      name: 'contact_name',
      label: 'Contact',
      required: true,
      disabled: !account_id,
      getData: getContact,
      async: true,
      id: 'contact_id',
      link: `${router_config.CONTACTS.path}/${data?.contact?.id}/summary`,
      paginationEnabled: true,
      nextBlock: contactPage,
      descId: 'customer_id',
      initialLoad: true,
      desc: 'customer_cre',
      type: 'auto_complete',
      placeholder: 'Enter Contact',
      value: data?.contact?.name,
      spacing: 6,
    },
    {
      name: 'assigned_to_name',
      label: 'Quote CRE',
      id: 'assigned_to',
      getData: loadEmployeeList,
      async: true,
      initialLoad: true,
      paginationEnabled: true,
      nextBlock: employeePage,
      desc: 'employee_name',
      descId: 'id',
      type: 'auto_complete',
      placeholder: 'Enter Quote CRE',
      value: data?.assigned_to?.name,
      required: true,
      spacing: 6,
    },
    {
      name: 'lead_source_name',
      label: 'Source Of Sale',
      id: 'lead_source_id',
      desc: 'lead_source_name',
      descId: 'id',
      getData: getLead,
      async: true,
      initialLoad: true,
      paginationEnabled: true,
      nextBlock: leadPage,
      type: 'auto_complete',
      placeholder: 'Enter Source Of Sale',
      value: data?.lead_source?.name,
      required: false,
      spacing: 6,
    },
    {
      ...textField(
        'internal_campaign_id',
        'Internal Campaign',
        'Enter Internal Campaign',
        data?.internal_campaign_id
      ),
      spacing: 6,
    },
    {
      ...textField(
        'total_services_count',
        'Service Count',
        'Enter Service Count',
        data?.total_services_count,
        true,
        'number',
        true
      ),
      spacing: 6,
    },
    // {
    //   ...textField(
    //     'spot_incentive',
    //     'Spot Incentive',
    //     'Enter Spot Incentive',
    //     data?.spot_incentive,
    //     false,
    //     'text',
    //     true
    //   ),
    //   spacing: 6,
    // },
    // {
    //   name: 'order_status_name',
    //   label: 'Quote Status',
    //   id: 'order_status_id',
    //   desc: 'order_status_name',
    //   descId: 'id',
    //   getData: getStatus,
    //   async: true,
    //   initialLoad: true,
    //   paginationEnabled: true,
    //   nextBlock: leadPage,
    //   type: 'auto_complete',
    //   placeholder: 'Enter Order Status',
    //   value: data?.order_status?.name,
    //   required: true,
    // },

    {
      ...textField(
        'datetime_created',
        'Created Date',
        'Enter Created Date',
        convertUTCtoBrowserTimeZone(data?.datetime_created)
      ),
      maxDate: new Date(),
      type: 'date',
      required: true,
      disabled: true,
      spacing: 6,
    },
    {
      ...textField(
        'datetime_updated',
        'Updated On',
        'Updated On',
        convertUTCtoBrowserTimeZone(data?.datetime_updated)
      ),
      disabled: true,
      spacing: 6,
    },
    {
      ...textField(
        'created_by',
        'Created By',
        'Enter Created By',
        data?.created_by?.name,
        true,
        'text',
        true
      ),
      spacing: 6,
    },
    {
      ...textField(
        'customer_communication_mode',
        'Customer Communication Mode',
        'Customer Communication Mode',
        data?.customer_communication_mode,
        false,
        'text',
        true
      ),
      spacing: 6,
    },
    {
      ...textField(
        'customer_message',
        'Customer Message',
        'Customer Message',
        data?.customer_message,
        false
      ),
      spacing: 12,
      type: 'textarea',
      disabled: true,
    },
    {
      ...textField(
        'order_comment',
        'Remarks',
        'Enter Remarks',
        data?.order_comment
      ),
      spacing: 12,
      type: 'textarea',
    },
    {
      ...textField(
        'quote_note',
        'Quote Notes',
        'Enter Quote Notes',
        data?.quote_note
      ),
      spacing: 12,
      type: 'textarea',
    },
  ]

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
  }

  const handleSubmission = () => {
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
    setIsEditing(!isEditing)
  }

  const { mutate, isLoading } = useEditQuote(handleSubmission)
  const onSubmit = (vals: OrderEditSchema) => {
    const input = {
      order_date: moment(vals?.order_date).format('YYYY-MM-DD'),
      contact_id: vals?.contact_id,
      account_id: vals?.account_id,
      assigned_to: vals?.assigned_to,
      lead_source_id: vals?.lead_source_id ? vals?.lead_source_id : null,
      internal_campaign_id: vals?.internal_campaign_id ?? '',
      // spot_incentive: vals?.spot_incentive,
      order_status_id: vals?.order_status_id,
      order_comment: vals?.order_comment ?? '',
      quote_note: vals?.quote_note ?? '',
      items: data.order_items || [],
    }
    mutate({ input: input, id: id ? id : '' })
  }
  const { handleSubmit } = methods
  const linkDatas = [
    ...(data?.deal?.id
      ? [
          {
            label: 'Connected Deal',
            value: data?.deal?.deal_number,
            id: data?.deal?.id,
            slug: 'deal',
          },
        ]
      : []),
    ...(data?.id && data?.order_number && !data?.is_quote
      ? [
          {
            label: 'Connected Order',
            value: data?.order_number,
            id: data?.id,
            slug: 'order',
          },
        ]
      : []),
  ]
  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={isEditing} spacing />
          </FormProvider>
        ) : (
          <>
            {formBuilderProps.map((basicInformation) => (
              <div
                key={data?.id}
                className={`col-span-12 md:col-span-${
                  basicInformation.spacing || 12
                }`}
              >
                <FormFieldView
                  value={basicInformation.value}
                  type={basicInformation?.type}
                  link={basicInformation.link}
                  required={basicInformation?.required}
                  label={basicInformation.label}
                />
              </div>
            ))}
          </>
        )}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="menu" />
          <h4 className=" font-bold text-primaryText">Basic Information</h4>
        </div>
        {checkPermission('change') && (
          <div className="iconBlack cursor-pointer">
            {isEditing ? (
              <div className="flex gap-2">
                <div>
                  <Button
                    onClick={handleCancel}
                    size="xs"
                    label="Cancel"
                    outlined={true}
                  />
                </div>
                <div>
                  <Button
                    size="xs"
                    label="Save"
                    outlined={false}
                    isLoading={isLoading}
                    onClick={handleSubmit((data) => onSubmit(data))}
                  />
                </div>
              </div>
            ) : (
              <>
                {allowActionsByStatusExcept(data?.order_status?.code) &&
                  data?.is_quote && (
                    <Icons onClick={handleEditClick} name="edit" />
                  )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="grid grid-cols-12 gap-4">{renderFields()}</div>
      <FormLinkView data={linkDatas} />
    </div>
  )
}

export default BasicInformation
