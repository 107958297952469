import moment from 'moment'

import Icons from '../../../../components/common/icons/index'
import { getNestedProperty } from '../../../../utilities/parsers'

const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({ statusClassGen }: any) => {
  const createRenderCell = (key: string, custom?: string) => (row: any) => {
    switch (custom) {
      case 'status':
        return {
          cell: (
            <div className="text-capitalize">
              <span
                className={`statusLabel  ${
                  row.status?.name
                    ? statusClassGen(row?.status?.name)
                    : 'Active'
                }`}
              >
                {row?.status?.name}
              </span>
            </div>
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      case 'icons':
        return {
          cell: (
            <div className="flex gap-1 items-center">
              {row?.customer.primary_company_id &&
                row?.customer.primary_company_id === row.company.id && (
                  <Icons name="favourite-colored" />
                )}
              <>{`${getNestedProperty(row, key) ?? ''}`}</>
            </div>
          ),
        }
      case 'created':
        return {
          cell: (
            <>
              {row?.datetime_created
                ? moment(row?.datetime_created).format('DD-MM-YYYY')
                : '--'}
            </>
          ),
        }

      default:
        return {
          cell: getNestedProperty(row, key),
          toolTip: getNestedProperty(row, key) ?? '',
        }
    }
  }

  const column = [
    {
      title: 'Created Date',
      field: 'datetime_created',
      ...defaultColumnProps3,
      renderCell: createRenderCell('datetime_created', 'created'),
      customCell: true,
    },
    {
      title: 'Services',
      field: 'service_names',
      ...defaultColumnProps3,
      colWidth: 1000,
    },
  ]

  return column
}
