import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import Button from '../../components/common/buttons/Button'
import Icons from '../../components/common/icons/index'
import ListingHeader from '../../components/common/ListingTiles'
import PageTitle from '../../components/common/PageTitle/index'
import { useSnackbarManager } from '../../components/common/snackbar'
import { router_config } from '../../configs/route.config'
import { useContactFilterStore } from '../../store/filterSore/contactStore'
import { calcWindowHeight } from '../../utilities/calcHeight'
import { getSortedColumnName } from '../../utilities/parsers'
import {
  TABlE_COLUMNS,
  useTablePreference,
} from '../../utilities/tableprefrences/index'
import { updateContactFranchisee, useContact } from './api'
import { getColumns } from './contactColumns'
import { checkPermission } from './permission'
import { getFullFranchiseeList } from '../../apis/common.apis'

const UnallottedContacts = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [mergeIsActive, setMergeIsActve] = useState(false)
  const { getSavedColumns, handleSaveColumns } = useTablePreference()
  const { enqueueSnackbar } = useSnackbarManager()
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  const { clearSelectedRows, advanceFilter } = useContactFilterStore()
  const [showDetail, setShowDetail] = useState(true)
  const [updateKey, setUpdateKey] = useState(1)
  const [franchisees, setFranchisees] = useState([])

  const { page, page_size, search, ordering, filters, filterProps } = pageParams

  const searchParams = {
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
    unallotted: true,
    ...filters,
  }
  const { data, refetch, isFetching } = useContact(searchParams)
  // useEffect(() => {
  //   refetch()
  // }, [page, page_size, search, ordering, filters, refetch])

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    if (from == 'contactName') {
      return `${router_config.UNALLOTTED_CONTACTS.path}/${r.id}/summary`
    } else {
      return `${router_config.UNALLOTTED_CONTACTS.path}/${r.id}/summary`
    }
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }
  const handleColumnToggle = async (columns: any) => {
    handleSaveColumns(columns, TABlE_COLUMNS.UNALLOTTED_CONTACT)
  }
  // const handleTileClick = (id: string) => {
  //   console.log(id)
  // }
  const basicData = {
    title: 'Unallotted Contacts',
    icon: 'user',
  }
  // const dashtiledata = [
  //   {
  //     id: 1,
  //     tiles: [
  //       {
  //         icon: 'user',
  //         label: 'Total Contacts',
  //         total: '$100',
  //         count: 10,
  //         id: 1,
  //       },
  //       {
  //         icon: 'calendar',
  //         label: 'Active Contacts',
  //         total: 100,
  //         count: 10,
  //         id: 2,
  //       },
  //       {
  //         icon: 'calendar',
  //         label: 'Inactive Contacts',
  //         total: 100,
  //         count: 10,
  //         id: 3,
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     tiles: [
  //       {
  //         label: 'Total Agents',
  //         icon: 'calendar',
  //         total: 100,
  //         count: 10,
  //         id: 1,
  //       },
  //       {
  //         label: 'Active Agents',
  //         icon: 'badge-check',
  //         total: 100,
  //         count: 0,
  //         id: 2,
  //       },
  //       {
  //         label: 'Total Orders',
  //         icon: 'calendar',
  //         isCount: true,
  //         id: 3,
  //       },
  //     ],
  //   },
  // ]

  useEffect(() => {
    if (
      filterProps.endDate ||
      (filterProps.endDate === null && filterProps.startDate === null)
    ) {
      handleDatepicker()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])
  useEffect(() => {
    clearSelectedRows()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleSHowHide = () => {
    return advanceFilter?.filterParams?.find((item) => item.isChecked)
      ? true
      : false
  }
  const handlePath = () => {
    if (location.pathname === router_config.CONTACT_MERGE.path) {
      setMergeIsActve(true)
    } else {
      setMergeIsActve(false)
    }
  }

  useEffect(() => {
    getFranchiseeList()
  }, [])

  useEffect(() => {
    handlePath()
    // handleRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const reDirection = router_config.CONTACTS.path

  const [columns, setColumns] = useState<any>([])

  useEffect(() => {
    setColumns(
      getSavedColumns(
        getColumns({
          onViewAction: onViewAction,
          franchisees,
          updateKey,
          handleFranchiseeeChange,
        }),
        TABlE_COLUMNS.UNALLOTTED_CONTACT
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [franchisees])

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        franchisees,
        updateKey,
        handleFranchiseeeChange,
      }),
    ])
    handleSaveColumns(
      getColumns({
        onViewAction: onViewAction,
        franchisees,
        updateKey,
        handleFranchiseeeChange,
      }),
      TABlE_COLUMNS.UNALLOTTED_CONTACT
    )
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, reDirection, refetch }} />
  }

  const handleFranchiseeeChange = (e: any, row: any) => {
    const details = { franchisee_id: e?.id ?? null }
    updateContactFranchisee(details, row?.id)
      .then(() => {
        enqueueSnackbar('Franchisee  assigned successfully', {
          variant: 'success',
        })
        refetch()
      })

      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.error?.message, {
          variant: 'error',
        })
      })
      .finally(() => {
        setUpdateKey((p) => p + 1)
      })
  }
  const getFranchiseeList = async () => {
    const { data } = await getFullFranchiseeList()

    const result = data.franchisees?.map((item: any) => ({
      ...item,
      franchisee_display_name:
        item?.franchisee_display_name ?? item?.franchisee_name,
    }))

    setFranchisees(result)
  }
  return (
    <div>
      <ListingHeader
        data={basicData}
        actionProps={{
          hideExport: true,
        }}
        checkPermission={checkPermission}
      />
      <PageTitle data={data?.total} isLoading={isFetching} />

      {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      <div className=" p-4">
        <QbsTable
          data={data?.results ?? []}
          dataRowKey="id"
          toolbar={true}
          selectedRows={selectedRows}
          selection
          // searchValue={searchKey}
          handleColumnToggle={handleColumnToggle}
          search={true}
          height={calcWindowHeight(422)}
          isLoading={isFetching}
          sortType={pageParams.sortType}
          sortColumn={pageParams.sortColumn}
          handleColumnSort={handleSort}
          columns={columns}
          pagination={true}
          handleResetColumns={() => handleResetColums()}
          paginationProps={{
            onPagination: onChangePage,
            total: data?.count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
              ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
              : [10, 20, 30, 50],
          }}
          actionProps={[
            {
              icon: <Icons name="eye" />,
              action: (row) => navigate(onViewAction(row, '')),
              title: 'view',
              toolTip: 'View',
            },
            // {
            //   title: 'Delete Contact',
            //   action: (rowData) => handleDeleteModel(rowData),
            //   icon: <Icons name="delete" />,
            //   toolTip: 'Delete Contact',
            //   hidden: !checkPermission('delete'),
            // },
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
          tableHeaderActions={
            <div className="flex gap-2">
              {handleSHowHide() && (
                <Button
                  onClick={() => {
                    setShowDetail(!showDetail)
                  }}
                  label={showDetail ? 'Hide' : 'Show'}
                  className="se"
                  primary
                />
              )}
            </div>
          }
        />
      </div>
    </div>
  )
}

export default UnallottedContacts
