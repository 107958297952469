import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { getContactDetails } from '../../api'
import AddressInformation from './addressInformation'
import BasicInformation from './basicInformation'
import ContactInformation from './contactInformation'
import { handleError } from '../../../../utilities/commonUtilities'
import { checkPermission } from '../../permission'

const UnallottedContactProfiles = () => {
  const { setActiveTab, handleParentCallback, detailData } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallback: () => void
    detailData?: any
  }>()
  const [data, setData] = useState<any>(detailData)
  const params = useParams()
  const [editFlag, setEditFlag] = useState(false)

  useEffect(
    () => {
      setActiveTab('UNALLOTTED_CONTACT_DETAILS_PROFILE')
      setData(detailData)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailData]
  )

  const getData = async () => {
    getContactDetails(params.id)
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        handleError(err)
      })
  }
  const handleRefresh = () => {
    getData()
    handleParentCallback?.()
  }

  const handleEditButton = (flag: boolean) => {
    setEditFlag(flag)
  }
  const handlePermission = () => {
    return false && checkPermission('change')
  }
  return (
    <div className="p-4 rounded-lg bg-cardWrapperBg gap-4 grid grid-cols-2">
      <BasicInformation
        data={data}
        id={params.id ?? ''}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        hasPermission={handlePermission()}
      />
      <ContactInformation
        data={data}
        id={params.id ?? ''}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        hasPermission={handlePermission()}
      />
      <AddressInformation
        data={data}
        id={params.id ?? ''}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        hasPermission={handlePermission()}
      />
    </div>
  )
}

export default UnallottedContactProfiles
