import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import InfoBox from '../../../../components/app/alertBox/infoBox'
import { DialogModal } from '../../../../components/common'
import Button from '../../../../components/common/buttons/Button'
import Icons from '../../../../components/common/icons/index'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import {
  allowActionsByStatusExcept,
  blockActionsbyStatus,
} from '../../../../configs/permissionGate'
import { MODULES } from '../../../../configs/scopes.config'
import { getErrorMessage } from '../../../../utilities/parsers'
import AddService from '../../addServices'
import { deleteQuoteService, useOrderServiceDetails } from '../../api'
import { checkDetailPermission } from '../../permission'
import { getColumns } from './servicesColumns'

const QuoteServices = () => {
  const { setActiveTab, handleParentCallBack, detailsData } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallBack?: () => void
    detailsData?: any
  }>()
  const [isAccountOpen, setIsAccountOpen] = useState(false)
  const [activeRow, setActiveRow] = useState<any>(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [detailData, setDetailData] = useState<any>(detailsData)

  const { enqueueSnackbar } = useSnackbarManager()

  const params = useParams()

  const handleAction = () => {
    setIsAccountOpen(true)
  }

  useEffect(
    () => {
      setActiveTab('QUOTE_DETAILS_SERVICES')
      setDetailData(detailsData)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleDelete = () => {
    deleteQuoteService(params.id, activeRow?.id)
      .then(() => {
        enqueueSnackbar('Service deleted successfully', { variant: 'success' })
        handleDeleteClose()
        handleParentCallBack?.()
        refetch()
      })
      .catch((error: any) => {
        enqueueSnackbar(getErrorMessage(error?.response?.data?.errors), {
          variant: 'error',
        })
      })
  }

  const { data, isFetching, refetch } = useOrderServiceDetails(
    params?.id ?? '',
    {}
  )

  // const onViewAction = (r: any) => {
  //   return `${router_config.SERVICE_AND_ORDERS_BY_SERVICE.path}/${r?.id}/summary`
  // }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setActiveRow(null)
  }
  const handleDeleteOpen = (row: any) => {
    setActiveRow(row)
    setDeleteOpen(true)
  }
  const handleEditClose = () => {
    setActiveRow(null)
    setEditOpen(false)
    setIsAccountOpen(false)
  }
  const handleEditOpen = (row: any) => {
    setActiveRow(row)
    setEditOpen(true)
    setIsAccountOpen(true)
  }
  const handleCallback = () => {
    refetch()
    handleParentCallBack?.()
    handleEditClose()
  }
  const handleClose = () => {
    handleEditClose()
  }

  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data ?? []}
          dataRowKey="id"
          toolbar={true}
          search={true}
          isLoading={isFetching}
          columns={getColumns() as any}
          tableHeaderActions={
            <div className="flex gap-2">
              <Button
                onClick={handleAction}
                label={'Add New'}
                className="se"
                hidden={!checkDetailPermission('add', MODULES.service)}
                outlined
                disabled={
                  !detailData?.is_quote ||
                  detailData?.has_active_quote_document ||
                  blockActionsbyStatus(detailData?.order_status?.code)
                }
              />
            </div>
          }
          actionProps={[
            ...(!detailData?.has_active_quote_document &&
            detailData?.is_quote &&
            allowActionsByStatusExcept(detailData?.order_status?.code)
              ? [
                  {
                    icon: <Icons name="delete" />,
                    action: (row: any) => handleDeleteOpen(row),
                    title: 'Delete',
                    hidden: !checkDetailPermission('delete', MODULES.service),
                    toolTip: 'Delete',
                  },
                ]
              : []),
            {
              icon: <Icons name="edit" />,
              action: (row: any) => handleEditOpen(row),
              title: 'Edit',
              //hidden: !checkDetailPermission('edit', MODULES.service),
              toolTip: 'Edit',
            },
          ]}
          columnToggle
        />
      </div>
      <AddService
        isDrawerOpen={isAccountOpen}
        isEditOpen={editOpen}
        serviceData={activeRow}
        subSection={true}
        handleClose={handleClose}
        handleCallback={handleCallback}
        id={params.id}
      />
      <DialogModal
        isOpen={deleteOpen}
        onClose={() => handleDeleteClose()}
        title={'Are you sure?'}
        onSubmit={() => handleDelete()}
        secondaryAction={() => handleDeleteClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              'Are you sure you want to delete this service from this deal?'
            }
          />
        }
      />
    </>
  )
}

export default QuoteServices
