import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosRequestConfig } from 'axios'

import {
  deleteData,
  getConfigData,
  getData,
  postData,
  updateData,
  patchData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { useSnackbarManager } from '../../components/common/snackbar'
import { getErrorMessage, parseQueryParams } from '../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.UNALLOTTED_CONTACT_LIST, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}

export const useContact = (input: QueryParams) => {
  return useQuery(['contact_list', input], () => fetchData(input), {
    // refetchOnWindowFocus: true,
    //staleTime: 50000,
  })
}

export const createContact = (input: any) => {
  return postData(apiUrl.CONTACT_CREATE, input)
}

export const useCreateContact = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createContact, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Contact Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const editContact = (data: { input: any; id: string }) => {
  return updateData(`${apiUrl.CONTACT_CREATE}${data?.id}/`, data?.input)
}

export const useEditContact = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editContact, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Contact Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const getContactMergeDetails = (data: any) => {
  return postData(apiUrl.CONTACT_MULTIPLE_DETAILS, data)
}

export const mergeContacts = (data: any, id?: string) => {
  return updateData(`${apiUrl.CONTACT_LIST_URL}${id}/merge/`, data)
}
export const getContactDetails = (id: any) => {
  return getData(`${apiUrl.UNALLOTTED_CONTACT_LIST}${id}`)
}

export const setPrimaryAccount = (id: string, data: any) => {
  return updateData(
    `${apiUrl.CONTACT_LIST_URL}${id}/set-primary-account/`,
    data
  )
}

// export const getContactAccountDetails = (id: any) => {
//   return getData(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/?page=1`)
// }

// const accountsfetchData = async (id: string, input: QueryParams) => {
//   const url = buildUrlWithParams(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/`, {

const accountsfetchData = async (id: string, input: QueryParams) => {
  const url = buildUrlWithParams(
    `${apiUrl.UNALLOTTED_CONTACT_LIST}${id}/related_accounts/`,
    {
      ...input,
    }
  )
  const response = await getData(url)
  return response?.data
}

export const useContactAccountDetails = (id: string, input: QueryParams) => {
  return useQuery(
    ['unallotted_contact_accounts', id, input],
    () => accountsfetchData(id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}
const quotesfetchData = async (id: string, input: QueryParams) => {
  const url = buildUrlWithParams(
    `${apiUrl.UNALLOTTED_CONTACT_LIST}${id}/related_quotes/`,
    {
      ...input,
    }
  )
  const response = await getData(url)
  return response?.data
}

export const useContactQuoteDetails = (id: string, input: QueryParams) => {
  return useQuery(
    ['unallotted_contact_quotes', id, input],
    () => quotesfetchData(id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}
export const getContactDuplicate = (data: any, config: AxiosRequestConfig) =>
  getConfigData(
    `${apiUrl.CONTACT_LIST_URL}possible-contact-duplicates/${parseQueryParams(
      data ?? {}
    )}`,
    config
  )

export const listContactDuplicate = (id?: string, data?: any) =>
  getData(
    `${apiUrl.CONTACT_LIST_URL}${id}/contact-duplicates/${parseQueryParams(
      data
    )}`
  )

export const updateContactCre = (id: string, data: any) => {
  return postData(`${apiUrl.CONTACT_LIST_URL}${id}/allocate-cre`, data)
}
export const deleteContactItem = (id?: string) => {
  return deleteData(`${apiUrl.CONTACT_LIST_URL}${id}`)
}
export const updateContactFranchisee = (input: any, id: string) => {
  return patchData(
    `${apiUrl.UNALLOTTED_CONTACT_LIST}${id}/set_franchisee/`,
    input
  )
}
