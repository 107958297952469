export const SCOPES: { [key: string]: string } = {
  add_customer: 'customer::add_customer',
  change_customer: 'customer::change_customer',
  customer: 'customer::customer',
  delete_customer: 'customer::delete_customer',
  view_all_customer: 'customer::view_all_customer',
  view_customer: 'customer::view_customer',
  export_customers: 'customer::export_customers',

  account: 'customer::account',
  add_customercompany: 'customer::add_customercompany',
  change_customercompany: 'customer::change_customercompany',
  delete_customercompany: 'customer::delete_customercompany',
  export_accounts: 'customer::export_accounts',
  view_all_accounts: 'customer::view_all_accounts',
  view_customercompany: 'customer::view_customercompany',

  add_employee: 'employee::add_employee',
  change_employee: 'employee::change_employee',
  change_employee_password: 'employee::change_employee_password',
  delete_employee: 'employee::delete_employee',
  employee: 'employee::employee',
  employee_dashboard: 'employee::employee_dashboard',

  manage_org: 'employee::manage_org',
  settings: 'employee::settings',
  view_employee: 'employee::view_employee',
  add_employeeteam: 'employee::add_employeeteam',
  change_employeeteam: 'employee::change_employeeteam',
  delete_employeeteam: 'employee::delete_employeeteam',
  manage_team: 'employee::manage_team',
  view_employeeteam: 'employee::view_employeeteam',
  view_paymentout: 'payment::view_paymentout',
  add_paymentout: 'payment::add_paymentout',
  export_paymentout: 'payment::export_paymentout',
  delete_paymentout: 'payment::delete_paymentout',
  edit_paymentout: 'payment::change_paymentout',

  add_order: 'order::add_order',
  add_order_items: 'order::add_order_items',
  add_payments: 'order::add_payments',
  change_order: 'order::change_order',
  delete_order: 'order::delete_order',
  delete_order_items: 'order::delete_order_items',
  delete_payments: 'order::delete_payments',
  order: 'order::order',

  payment_approvals: 'order::payment_approvals',
  reports: 'order::payment_approvals',
  sales: 'order::sales',
  view_all_order: 'order::view_all_order',
  view_order: 'order::view_order',
  view_team_order: 'order::view_team_order',
  list_invoice: 'invoice::list_invoice',
  view_payment: 'payment::view_payment',
  manage_export: 'employee::manage_export',
  export_order_items: 'order::export_order_items',
  export_orders: 'order::export_orders',
  export_payment_approvals: 'order::export_payment_approvals',
  view_lead: 'crm::view_lead',

  enquiries: 'support::manage_support',
  view_all_enquiries: 'support::view_all_support',
  view_enquiries: 'support::view_support',
  view_team_enquiries: 'support::view_team_support',
}
// const codenameAclObject = array.reduce((obj, item) => {
//   obj[item.codename] = item.acl
//   return obj
// }, {})

// console.log(codenameAclObject)

export const MODULES = {
  task: 'task',
  note: 'note',
  eventlog: 'eventlog',
  notecomment: 'notecomment',
  attachment: 'attachment',
  service: 'service',
  quotedocument: 'quotedocument',
  quotation: 'quote_quotedocument',
  agent: 'agent',
  dashboard: 'dashboard',

  lead: 'lead',
  unallotted_lead: 'unallotted_lead',
  lead_attachment: 'lead_attachment',
  lead_possibleduplicate: 'lead_possibleduplicate',
  lead_note: 'lead_note',
  lead_eventlog: 'lead_eventlog',
  lead_task: 'lead_task',
  merge_lead: 'merge_lead',

  deal: 'deal',
  deal_attachment: 'deal_attachment',
  deal_possibleduplicate: 'deal_possibleduplicate',
  deal_note: 'deal_note',
  deal_eventlog: 'deal_eventlog',
  deal_task: 'deal_task',
  deal_service: 'deal_service',
  merge_deal: 'merge_deal',

  quote: 'quote',
  quote_attachment: 'quote_attachment',
  quote_possibleduplicate: 'quote_possibleduplicate',
  quote_note: 'quote_note',
  quote_eventlog: 'quote_eventlog',
  quote_task: 'quote_task',
  quote_service: 'quote_service',
  quote_quotedocument: 'quote_quotedocument',
  quote_quotation: 'quote_quotation',

  account: 'customercompany',
  account_attachment: 'customercompany_attachment',
  account_possibleduplicate: 'customercompany_possibleduplicate',
  account_note: 'customercompany_note',
  account_eventlog: 'customercompany_eventlog',
  account_task: 'customercompany_task',
  account_service: 'customercompany_service',
  merge_account: 'merge_customercompany',
  account_order: 'customercompany_order',
  account_agent: 'customercompany_agent',
  account_contact: 'customercompany_customer',

  contact: 'customer',
  contact_attachment: 'customer_attachment',
  contact_possibleduplicate: 'customer_possibleduplicate',
  contact_note: 'customer_note',
  contact_eventlog: 'customer_eventlog',
  contact_task: 'customer_task',
  contact_service: 'customer_service',
  merge_contact: 'merge_customer',
  contact_account: 'customer_customercompany',

  order: 'order',
  order_attachment: 'order_attachment',
  order_note: 'order_note',
  order_eventlog: 'order_eventlog',
  order_task: 'order_task',
  order_payment: 'order_payment',
  order_service: 'order_orderitem',
  order_invoice: 'order_invoice',
  orderService: 'orderitem',
  order_receipt: 'order_receipt',
  order_activitylog: 'order_activity_log',

  payment: 'payment',
  paymentout: 'paymentout',
  receipt: 'receipt',
  invoice: 'invoice',
  document_invoice: 'document_invoice',
  request: 'request',

  services: 'orderitem',
  services_attachment: 'orderitem_attachment',
  services_note: 'orderitem_note',
  services_eventlog: 'orderitem_eventlog',
  services_task: 'orderitem_task',
  services_payment: 'orderitem_payment',
  services_activity: 'orderitem_orderitemtaskemployee',
  services_payment_approval: 'orderitem_payment_approval',

  payment_receipt: 'payment_receipt',
  activity: 'orderitemtaskemployee',
  activity_attachment: 'orderitemtaskemployee_attachment',
  activity_note: 'orderitemtaskemployee_note',
  activity_eventlog: 'orderitemtaskemployee_eventlog',
  activity_task: 'orderitemtaskemployee_task',
  activity_request: 'orderitemtaskemployee_request',
  export: 'export',

  role: 'group',
  employee: 'employee',
  employeeFranchisee: 'employee_franchisee',
  employeeTeam: 'employee_team',
  employeePassword: 'employee_password',
  team: 'team',
  franchisee: 'franchisee',
  group: 'group',
  report: 'report',
  employeeteam: 'employeeteam',
  employeeteam_member: 'employeeteam_member',
  employeeteam_service: 'employeeteam_service',

  stakeholder: 'stakeholder',
  stakeholderTeam: 'stakeholder_team',

  prospect: 'prospect',
  merge_prospect: 'merge_prospect',
  prospectNote: 'prospect_note',
  prospectEventlog: 'prospect_eventlog',
  prospectTask: 'prospect_task',
  prospectAttachment: 'prospect_attachment',
  prospect_service: 'prospect_service',

  enquiry: 'support',
}
