import { QbsTable } from 'qbs-react-grid'
import { useEffect } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { router_config } from '../../../../configs/route.config'
import { useAccountFilterStore } from '../../../../store/filterSore/associatedAccountStore'
import { statusClassGen } from '../../../../utilities/generators'
import { getSortedColumnName } from '../../../../utilities/parsers'
import { useContactAccountDetails } from '../../api'
import { getColumns } from './accountColumns'

const UnallottedContactAccounts = () => {
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useAccountFilterStore()

  const params = useParams()
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallback: () => void
  }>()

  useEffect(
    () => {
      setActiveTab('UNALLOTTED_CONTACT_DETAILS_ACCOUNTS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { page, page_size, search, ordering } = pageParams

  const searchParams = {
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
  }

  const { data, isFetching } = useContactAccountDetails(
    params?.id ?? '',
    searchParams
  )
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }

  const onViewAction = (r: any) => {
    return `${router_config.ACCOUNTS.path}/${r?.company?.id}/summary`
  }

  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }
  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data ?? []}
          dataRowKey="id"
          toolbar={true}
          selectedRows={selectedRows}
          // selection={true}
          search={true}
          isLoading={isFetching}
          sortType={pageParams.sortType}
          sortColumn={pageParams.sortColumn}
          handleColumnSort={handleSort}
          columns={getColumns({
            onViewAction: onViewAction,
            statusClassGen: statusClassGen,
          })}
          // pagination={true}
          // paginationProps={{
          //   onPagination: onChangePage,
          //   total: data?.count,
          //   currentPage: pageParams?.page,
          //   rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
          //   onRowsPerPage: onChangeRowsPerPage,
          //   dropOptions: [10, 20, 30, 50]
          // }}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
        />
      </div>
    </>
  )
}

export default UnallottedContactAccounts
