import { z } from 'zod'

import noLeadingSpaces from '../../../../../utilities/noLeadingSpaces'

export const formSchema = z.object({
  first_name: z
    .string({ invalid_type_error: 'First Name is required.' })
    .min(1, { message: 'First Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  last_name: z
    .string()
    .min(1, { message: 'Last Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),

  mobile: z
    .string()
    .min(1, { message: 'Mobile Number is required.' })
    .refine(
      (value) => {
        return (
          value === '' ||
          /^\+\d{1,4}$/.test(value) ||
          /^(\+?\d{10,13})$/.test(value)
        )
      },
      {
        message: 'Invalid mobile number.',
      }
    ),

  email: z
    .string()
    .min(1, { message: 'Email is required.' })
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
})

export type EmployeeSchema = z.infer<typeof formSchema>
export const roleSchema = z
  .object({
    role: z.string().optional().nullable(),
    role_id: z.union([z.string(), z.number()]).optional(),
    flag: z.string().optional().nullable(),
  })
  .superRefine((data, ctx) => {
    if (data.flag === 'changeRole' && data.role_id == '') {
      ctx.addIssue({
        path: ['role'],
        message: 'Required',
        code: z.ZodIssueCode.custom,
      })
    }
  })

export type RoleSchema = z.infer<typeof roleSchema>

export const teamSchema = z.object({
  team: z.string().min(1, 'Required'),
  team_id: z.string().min(1, 'Required'),
})

export type TeamSchema = z.infer<typeof teamSchema>

export const workProfileSchema = z.object({
  department: z.string().min(1, 'Required'),
  department_id: z.string().min(1, 'Required'),

  designation: z.string().min(1, 'Required'),
  designation_id: z.string().min(1, 'Required'),

  reports_to: z.string().min(1, 'Required'),
  reports_to_id: z.string().min(1, 'Required'),
})

export type WorkProfileSchema = z.infer<typeof workProfileSchema>
