import { z } from 'zod'

import noLeadingSpaces from '../../../utilities/noLeadingSpaces'

export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  // 'image/svg',
  // 'image/svg+xml',
]
const MAX_FILE_SIZE = 5000000

export const formSchema = z.object({
  vendor_name: z
    .string({ invalid_type_error: 'Name is required.' })
    .min(1, { message: 'Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  vendor_display_name: z
    .string({ invalid_type_error: 'Display name is required.' })
    .min(1, { message: 'Display name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  email: z
    .string()
    .email()
    .min(1, { message: 'Email is required.' })
    .refine(noLeadingSpaces, {
      message: 'Email is required',
    }),
  phone: z
    .string()
    .min(1, { message: 'Phone Number is required.' })
    .refine((value) => value === '' || /^(\+?\d{10,12})$/.test(value), {
      message: 'Invalid phone number.',
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  website: z
    .string()
    .min(1, { message: 'Website is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .refine(
      (value) =>
        value === '' ||
        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(
          value
        ),
      {
        message: 'Invalid Website.',
      }
    ),

  address_1: z
    .string()
    .min(1, { message: 'Address line 1 is required.' })
    .refine((value) => value.length < 149, {
      message: 'Maximum character reached.',
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  address_2: z
    .string()
    .min(1, { message: 'Address line 2 is required.' })
    .refine((value) => value.length < 149, {
      message: 'Maximum character reached.',
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  city: z
    .string()
    .min(1, { message: 'City is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  state: z
    .string({ invalid_type_error: 'State is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  state_name: z
    .string({ invalid_type_error: 'State is required.' })
    .min(1, { message: 'State is required.' }),

  pin_code: z
    .string()
    .min(1, { message: 'Pin code is required.' })
    .refine((value) => value === '' || /^[1-9][0-9]{5}$/.test(value), {
      message: 'Pincode must be a 6-digit number.',
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  country: z
    .string({ invalid_type_error: 'Country is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  country_name: z
    .string({ invalid_type_error: 'Country is required.' })
    .min(1, { message: 'Country is required.' }),
  gst_no: z
    .string()
    .refine(
      (value) =>
        value === '' ||
        /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([A-Z0-9]){1}$/.test(
          value
        ),
      {
        message: 'Invalid GST number format.',
      }
    )
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  pan_no: z
    .string()
    .refine((value) => value === '' || /^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value), {
      message: 'Invalid PAN number format',
    })
    .refine((value) => value === '' || noLeadingSpaces(value), {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  cin: z
    .string()
    // .min(1, { message: 'CIN required.' })
    .refine(
      (value) =>
        value === '' ||
        /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/.test(
          value
        ),
      {
        message: 'Invalid CIN number format.',
      }
    )
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  place_of_supply_id: z
    .string({ invalid_type_error: 'Place of supply is required.' })
    .nullable()
    .optional(),
  place_of_supply_name: z
    .string({ invalid_type_error: 'Place of supply is required.' })
    .nullable()
    .optional(),
  contact_first_name: z
    .string({ invalid_type_error: 'First Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  contact_last_name: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  contact_email: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  contact_phone: z
    .string()
    .refine(
      (value) => {
        return (
          value === '' ||
          /^\+(\d{1,4})$/.test(value) ||
          /^(\+?\d{10,12})$/.test(value)
        )
      },
      {
        message: 'Invalid mobile number.',
      }
    )
    .nullable()
    .optional(),
  remark: z.string().optional().nullable(),
  logo: z
    .union([z.instanceof(File), z.any()])
    .refine((file) => !(file instanceof File) || file.size <= MAX_FILE_SIZE, {
      message: 'Maximum size 5mb',
    })
    .refine(
      (file) =>
        !(file instanceof File) || ACCEPTED_IMAGE_TYPES.includes(file.type),
      {
        message: 'File type not allowed',
      }
    )
    .optional(),
  status: z.string().optional().nullable(),
})

export const basicSchema = z.object({
  vendor_name: z
    .string({ invalid_type_error: 'Name is required.' })
    .min(1, { message: 'Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  vendor_display_name: z
    .string({ invalid_type_error: 'Display name is required.' })
    .min(1, { message: 'Display name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  email: z
    .string()
    .min(1, { message: 'Email is required.' })
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  phone: z
    .string()
    .min(1, { message: 'Phone Number is required.' })
    .refine((value) => value === '' || /^(\+?\d{10,12})$/.test(value), {
      message: 'Invalid phone number.',
    }),
  website: z
    .string({ invalid_type_error: 'Website is required.' })
    .min(1, { message: 'Website is required.' })
    .refine(
      (value) =>
        value === '' ||
        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(
          value
        ),
      {
        message: 'Invalid Website.',
      }
    ),
  gst_no: z
    .string({ invalid_type_error: 'GST No is required.' })
    .refine(
      (value) =>
        value === '' ||
        /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([A-Z0-9]){1}$/.test(
          value
        ),
      {
        message: 'Invalid GST number format.',
      }
    )
    .nullable()
    .optional(),
  pan_no: z
    .string({ invalid_type_error: 'PAN No is required.' })
    .refine((value) => value === '' || /^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value), {
      message: 'Invalid PAN number format',
    })
    .refine((value) => value === '' || noLeadingSpaces(value), {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  cin: z
    .string({ invalid_type_error: 'CIN No is required.' })
    // .min(1, { message: 'CIN required.' })
    .refine(
      (value) =>
        value === '' ||
        /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/.test(
          value
        ),
      {
        message: 'Invalid CIN number format.',
      }
    )
    .nullable()
    .optional(),
  place_of_supply_id: z
    .string({ invalid_type_error: 'Place of supply is required.' })
    .nullable()
    .optional(),
  place_of_supply_name: z
    .string({ invalid_type_error: 'Place of supply is required.' })
    .nullable()
    .optional(),
  contact_first_name: z
    .string({ invalid_type_error: 'First Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  contact_last_name: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  contact_email: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  contact_phone: z
    .string()
    .refine(
      (value) => {
        return (
          value === '' ||
          /^\+(\d{1,4})$/.test(value) ||
          /^(\+?\d{10,12})$/.test(value)
        )
      },
      {
        message: 'Invalid mobile number.',
      }
    )
    .nullable()
    .optional(),
  remark: z.string().optional().nullable(),
  logo: z
    .union([z.instanceof(File), z.any()])
    .refine((file) => !(file instanceof File) || file.size <= MAX_FILE_SIZE, {
      message: 'Maximum size 5mb',
    })
    .refine(
      (file) =>
        !(file instanceof File) || ACCEPTED_IMAGE_TYPES.includes(file.type),
      {
        message: 'File type not allowed',
      }
    )
    .optional(),
  status: z.string().optional().nullable(),
})

export const addressSchema = z.object({
  address_1: z
    .string({ invalid_type_error: 'Address line 1 is required.' })
    .min(1, { message: 'Address line 1 is required.' })
    .refine((value) => value.length < 149, {
      message: 'Maximum character reached.',
    }),
  address_2: z
    .string({ invalid_type_error: 'Address line 2 is required.' })
    .min(1, { message: 'Address line 2 is required.' })
    .refine((value) => value.length < 149, {
      message: 'Maximum character reached.',
    }),
  city: z
    .string({ invalid_type_error: 'City is required.' })
    .min(1, { message: 'City is required.' }),
  state: z
    .string({ invalid_type_error: 'State is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  state_name: z
    .string({ invalid_type_error: 'State is required.' })
    .min(1, { message: 'State is required.' }),
  pin_code: z
    .string({ invalid_type_error: 'Pincode is required.' })
    .min(1, { message: 'Pin code is required.' })
    .refine((value) => value === '' || /^[1-9][0-9]{5}$/.test(value), {
      message: 'Pincode must be a 6-digit number.',
    }),
  country: z
    .string({ invalid_type_error: 'Country is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  country_name: z
    .string({ invalid_type_error: 'Country is required.' })
    .min(1, { message: 'Country is required.' }),
})

export const contactSchema = z.object({
  contact_first_name: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  contact_last_name: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  contact_email: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  contact_phone: z
    .string()
    .refine(
      (value) => {
        return (
          value === '' ||
          /^\+(\d{1,4})$/.test(value) ||
          /^(\+?\d{10,12})$/.test(value)
        )
      },
      {
        message: 'Invalid mobile number.',
      }
    )
    .nullable()
    .optional(),
})

const noLeadingSpacecheck = z.string().refine(noLeadingSpaces, {
  message: 'Leading spaces are not allowed',
})

// Regex for URL validation
const urlPattern =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/

const validateUrl = (url: string) => urlPattern.test(url)

// Define the payment schema
export const paymentSchema = z
  .object({
    default_payment_gateway: noLeadingSpacecheck,
    default_payment_gateway_name: z
      .string()
      .min(1, { message: 'Required' })
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed',
      }),
    razorpay_public_key: noLeadingSpacecheck.optional().nullable(),
    razorpay_secret_key: noLeadingSpacecheck.optional().nullable(),

    razorpay_redirection_url: noLeadingSpacecheck.optional().nullable(),

    easebuzz_merchant_key: noLeadingSpacecheck.optional().nullable(),
    easebuzz_salt: noLeadingSpacecheck.optional().nullable(),
    easebuzz_redirection_url: noLeadingSpacecheck.optional().nullable(),
  })
  .superRefine((data, ctx) => {
    if (data.default_payment_gateway === 'razorpay') {
      ;[
        'razorpay_public_key',
        'razorpay_secret_key',
        'razorpay_redirection_url',
      ].forEach((field: any) => {
        if (field === 'razorpay_redirection_url') {
          if (!validateUrl(data.razorpay_redirection_url as string)) {
            ctx.addIssue({
              path: ['razorpay_redirection_url'],
              message: 'Invalid  URL',
              code: z.ZodIssueCode.custom,
            })
          }
        }
        if (
          data[field as keyof typeof data] === null ||
          data[field as keyof typeof data] === ''
        ) {
          ctx.addIssue({
            path: [field],
            message: `Required`,
            code: z.ZodIssueCode.custom,
          })
        }
      })
    } else if (data.default_payment_gateway === 'easebuzz') {
      ;[
        'easebuzz_merchant_key',
        'easebuzz_salt',
        'easebuzz_redirection_url',
      ].forEach((field) => {
        if (field === 'easebuzz_redirection_url') {
          if (!validateUrl(data.easebuzz_redirection_url as string)) {
            ctx.addIssue({
              path: ['easebuzz_redirection_url'],
              message: 'Invalid  URL',
              code: z.ZodIssueCode.custom,
            })
          }
        }
        if (
          data[field as keyof typeof data] === null ||
          data[field as keyof typeof data] === ''
        ) {
          ctx.addIssue({
            path: [field],
            message: `Required `,
            code: z.ZodIssueCode.custom,
          })
        }
      })
    }
  })

export type VendorSchema = z.infer<typeof formSchema>
export type basicInformationSchema = z.infer<typeof basicSchema>
export type addressInformationSchema = z.infer<typeof addressSchema>
export type contactInformationSchema = z.infer<typeof contactSchema>
export type PaymentSchema = z.infer<typeof paymentSchema>
